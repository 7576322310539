import React from 'react';
import Project from './Project';
import Thumbnail from './Thumbnail';

let shuffledProjects;
let projects;
let projectsArray;

class Projects extends React.Component {

	constructor( props ) {
		super(props);
	}

	componentDidMount() {
		let projects = document.querySelectorAll( '.project' ), i;
		let visualIndex = document.querySelector( '.visual-index' );
		for (i = visualIndex.children.length; i >= 0; i--) {
	    	visualIndex.appendChild(visualIndex.children[Math.random() * i | 0]);
		}
	}


	render() {

		return (
	
			<>
				{this.props.projects.nodes.map((project, i) => (
					<Thumbnail 
						allProjects={ this.props.projects }
						project={ project } 
						key={i} 
						index={i}
						project={ project } 
						openModal={ this.props.openModal }
						setCategory={ this.props.setCategory }
						setSlide={ this.props.setSlide }
						setSlides={ this.props.setSlides }
						setTitle={ this.props.setTitle }
						setSlug={ this.props.setSlug }
					/>
				))}			
			</>

		);

	}

}

export default Projects;