import React, { useState } from 'react';
import { Swiper, SwiperSlide, initialSlide } from 'swiper/react';
import { EffectFade, } from 'swiper';
import Img from 'gatsby-image';
import '../../styles/components/full-gallery.scss';
import '../../styles/components/swiper-controls.scss';

import 'swiper/css';

import SwiperCore, {
  Keyboard,
  Navigation,
  Pagination
} from 'swiper';

SwiperCore.use([Keyboard, Navigation, Pagination]);

let slide, img;
export default function Gallery( props ) {
	// console.log(props.setSlide);
	let slideNumber = (parseInt(props.setSlide) + 1);
	console.log(slideNumber);
	return (
		<>
			<Swiper 
				keyboard={{
					enabled: true
				}} 
				navigation={{
					prevEl: '.slide-prev',
					nextEl: '.slide-next'
				}}

				pagination={{
					el: '.pagination',
					type: 'fraction'
				}}
				modules={[EffectFade]}
				effect="fade"
				slidesPerView={ 1 }
				loop={ true }
				speed={ 0 }
				className="full-gallery"
				onInit={(swiper) => {
						swiper.slideTo(slideNumber);
				}}
				onAfterInit={(swiper) => {
					window.addEventListener('popstate', function (event) {
						window.location.href = '/visual-index/';
					});
				}}
				
				onTransitionEnd={(index, swiper) => {
					slide = document.querySelector( '.swiper-slide-active' );
					props.setTitle( slide.dataset.caption );
					window.history.pushState(null, null, '/visual-index/' + props.gallerySlug);
				}}
			>

			<header 
				className={ props.galleryToggled ? 'flex text:header sequence': 'flex text:header' }
			>
				{ props.galleryTitle && (
					<h1>{ props.galleryTitle }</h1>
				)}

				{ props.galleryCategory && (
					<p className={ 'text:small' }>{ props.galleryCategory }</p>
				)}

			</header>
				{ props.setSlides && 
					props.setSlides.visualIndexGallery.map(( slide, i ) => (
						<SwiperSlide data-title={ props.galleryCategory } data-caption={ slide.caption } >
							<figure>
							{ slide.image.localFile.extension === 'gif' && (
								<img style={{ height: '100vh', objectFit: 'contain', height: '100%' }} src={ slide.image.localFile.publicURL } />
							)}

							{ slide.image.localFile.extension !== 'gif' && (
								<Img 
									style={{
										height: '100vh'
									}}
									imgStyle={{
										objectFit: 'contain',
										height: '100%'
									}}
									fadeIn={false}
									loading="eager"
									fluid={ slide.image.localFile.childImageSharp.fluid }
								/>
							)}
						</figure>
						</SwiperSlide>
					)
				)}

				<div className="slide-prev"></div>
				<div className="slide-next"></div>
			</Swiper>
		</>
	);

}