import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import '../styles/components/site-footer.scss';

export default function Footer() {

	const containerRef = useRef(null);
	const [ isVisible, setIsVisible ] = useState(false);

	const callbackFunction = (entries) => {
		const [ entry ] = entries
		setIsVisible(entry.isIntersecting)
	}

	const options = {
		root: null,
		rootMargin: "0px",
		threshold: 1.0
	}

	useEffect(() => {
		const observer = new IntersectionObserver(callbackFunction, options)
		if( containerRef.current ) observer.observe(containerRef.current)

		return () => {
			if(containerRef.current) observer.unobserve(containerRef.current)
		}

	}, [containerRef, options])

	return(
		<footer className={ "site-footer wrap--furniture flex text:small"}>
			<p className="text:small"><span>&copy;</span>{ new Date().getFullYear() } Polytechnic.  
				<Link to={ '/terms/' }>Terms</Link>.
				<Link to={ '/equality-diversity-and-inclusion/' }>Equality Diversity &amp; Inclusion</Link> 
			</p>

			<a ref={containerRef} className={ isVisible ? "visible top" : "top" }  onClick={(e) => {
				e.preventDefault();
				window.scrollTo({
					top: 0, 
					behavior: 'smooth'}
				);

			}} href="#top">
				<span className={ 'desktop' }>Back to top</span> 
				<span className={ 'mobile' }>Top</span>
			</a>
		</footer>
	);
	
}