import React, { Component } from 'react';
import '../../styles/components/controls.scss';

class Controls extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			hide: false
		}

	}

	componentDidMount() {
	  window.addEventListener('scroll', this.listenToScroll)
	}

	componentWillUnmount() {
	  window.removeEventListener('scroll', this.listenToScroll)
	}

	listenToScroll = () => {
	  const winScroll =
	    document.body.scrollTop || document.documentElement.scrollTop

	  const height =
	    document.documentElement.scrollHeight -
	    document.documentElement.clientHeight

	  const scrolled = winScroll / height

	  if( scrolled > 0.95 ) {
		  this.setState({
		    hide: this.state.hide = true
		  })
	  } else {
	  	this.setState({
	  		hide: this.state.hide = false
	  	})
	  }

	}

	render() {

		return ( 
			<nav className={ this.state.hide ? 'controls flex' : 'controls flex visible' }>
				<a 
					onClick={ this.props.zoomIn } 
					aria-label="Zoom in" 
					href="#plus" 
					className="plus">
				</a>
				<a 
					onClick={ this.props.zoomOut }
					aria-label="Zoom out" 
					href="#minus" 
					className="minus">
				</a>
				<a
					onClick={ this.props.contrast }
					aria-label="Contrast switch"
					href="#contrast"
					className="contrast">
					<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19"><defs></defs><path d="m13.04 5.96 4.95 4.95M14.45 4.55l3.54 3.54M4.55 14.45l3.54 3.54M5.96 13.04l4.95 4.95M7.38 11.62l5.66 5.66M8.79 10.21l5.66 5.65M10.21 8.79l5.65 5.66M11.62 7.38l5.66 5.66"/><circle class="cls-1" cx="9.5" cy="9.5" r="8.5"/></svg>
				</a>
				<a
					onClick={ this.props.shuffle }
					aria-label="Shuffle order of projects"
					href="#shuffle"
					className="shuffle">
				<svg viewBox="0 0 35 35"><path fill="none" stroke="#000" stroke-miterlimit="10" d="m24.513 2.393 5.862 5.862-5.862 5.861m-12.67 8.238a8.883 8.883 0 0 1-4.845 1.709H3.571"/><path fill="none" stroke="#000" stroke-miterlimit="10" d="M16.867 12.412c2.018-3.027 5.419-3.943 8.098-4.158h5.201m-5.653 24.352 5.862-5.859-5.862-5.862"/><path fill="none" stroke="#000" stroke-miterlimit="10" d="M30.166 26.746h-5.201c-3.559-.292-8.473-1.823-9.596-8.035l-.204-.919a8.574 8.574 0 0 0-8.167-6.854H3.571"/></svg>
				</a>
			</nav>
		);
	}
}

export default Controls;