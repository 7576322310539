import React, { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Gallery from '../components/visual-index/Gallery';
import { graphql, StaticQuery } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Projects from '../components/visual-index/Projects';
import Controls from '../components/visual-index/Controls';

import '../styles/components/close-modal.scss';
import '../styles/components/category.scss';
import '../styles/utils/modal-theme.scss';
import '../styles/components/modal.scss';

const fadeModal = {
  hidden: { opacity: 0 },
  show: { opacity: 1 }
}

let theWindow = typeof window !== 'undefined' ? window : '';

class VisualIndex extends React.Component {

	constructor( props ) {
		super( props )
		this.state = {
			columns: 6,
			modal: false,
			setSlides: [],
			slideIndex: '',
			slides: [],
			title: '',
			category: '',
			loadingOrder:[],
			slug: '',
			width: theWindow.innerWidth
		}

		this.increaseZoom = this.increaseZoom.bind( this )
		this.decreaseZoom = this.decreaseZoom.bind( this )
		this.modalOpen = this.modalOpen.bind( this )
		this.setCategory = this.setCategory.bind( this )
		this.setSlide = this.setSlide.bind( this )
		this.setSlides = this.setSlides.bind( this )
		this.setTitle = this.setTitle.bind( this )
		this.setSlug = this.setSlug.bind( this )
		this.updateWindowDimensions = this.updateWindowDimensions.bind( this )
		this.setLoadingOrder = this.setLoadingOrder.bind( this )

	}

  	componentDidMount() {

    	let currentWidth = this.state.width;
    	
    	window.addEventListener( 'resize', (e) => {
    		if( window.innerWidth === currentWidth ) return;
    		this.updateWindowDimensions();
    	});
    	
    	this.setStates();

  	}

	setStates = () => {
		
		return this.setState({
			columns: this.state.width < 630 ? 6 : 5,
			modal: false
		});

	}

	modalOpen = (e) => {
	
		this.setState({
			modal: true,
		}, () => {
			this.customCursor(e);
		});

		setTimeout(() => {
 	    document.body.classList.add( 'freeze' );
			document.body.classList.add( 'reveal-header' );
		}, 250);

		setTimeout(() => {
			document.body.classList.add( 'reveal-gallery' );
		}, 1150);




	}

	modalClose = (e) => {
    e.preventDefault();

		document.body.classList.remove( 'reveal-gallery' );
		document.body.classList.remove( 'reveal-header' );
    document.body.classList.remove( 'freeze' );

    this.setState({ 
    	modal: false 
    });

   	window.history.pushState(null, null, '/visual-index/');

	}

	contrastMode = (e) => {
		e.preventDefault();
		document.body.classList.toggle( 'bg:body' );
	}

	shuffle = (e) => {
		e.preventDefault();
		let projects = document.querySelectorAll( '.project' ), i;
		let visualIndex = document.querySelector( '.visual-index' );
		for (i = visualIndex.children.length; i >= 0; i--) {
    	visualIndex.appendChild(visualIndex.children[Math.random() * i | 0]);
		}
	}

	setSlide = ( index ) => {

		this.setState({
			slideIndex: this.state.slideIndex = index
		});

	}

	setSlides = ( gallery ) => {

		this.setState({
			setSlides: this.state.slides = gallery			
		});

	}

	setTitle = ( title ) => {

		this.setState({
			title: this.state.title = title
		});

	}

	setSlug = ( slug ) => {
		this.setState({
			slug: this.state.slug = slug
		});

	}

	setLoadingOrder = ( projects ) => {

		this.setState({
			loadingOrder: this.state.loadingOrder = projects
		});

	}

	decreaseZoom(e) {
		e.preventDefault();

		if( this.state.columns < 7 ) {
			this.setState({
				columns: this.state.columns = this.state.columns + 1
			});
		}

	}

	increaseZoom(e) {
		e.preventDefault();

		let max;
		if( this.state.width < 630 ) {
			max = 5;
		} else {
			max = 2;
		}

		if( this.state.columns > max ) {
			this.setState({
				columns: this.state.columns = this.state.columns - 1
			});
		}

	}

	setCategory( category ) {
		this.setState({
			category: this.state.category = category
		});
	}

	customCursor(e) {

		const body = document.body;
		const cursor = document.querySelector( '.cursor' );
		const theWindow = window.innerWidth;
		const centre = theWindow / 2;

		cursor.style.left = e.clientX + "px";
		cursor.style.top = e.clientY + "px";

		if( e.clientX > centre ) {
			cursor.classList.add( 'cursor--flipped' );
		} else {
			cursor.classList.remove( 'cursor--flipped' );
		}

		body.addEventListener( "mousemove", (n, event) => {
			cursor.style.left = n.clientX + "px";
			cursor.style.top = n.clientY + "px";

			if( n.clientX > centre ) {
				cursor.classList.add( 'cursor--flipped' );
			} else {
				cursor.classList.remove( 'cursor--flipped' );
			}

		});

	}

	updateWindowDimensions() {

		this.setState({
			columns: this.state.columns = this.state.width < 630 ? 6 : 5,
			width: theWindow.innerWidth
		});

	}

	render() {

		return(
			<>
			<StaticQuery 
				query={graphql`query MyQuery {
					  wpPage(id: {eq: "cG9zdDoxMQ=="}) {
					    id
					    title
					    uri
					    seo {
					      metaDesc
					      metaKeywords
					      metaRobotsNoindex
					      focuskw
					      opengraphAuthor
					      opengraphDescription
					      opengraphImage {
					        localFile {
					          childImageSharp {
					            fixed {
					              src
					            }
					          }
					        }
					      }
					      opengraphModifiedTime
					      opengraphPublishedTime
					      opengraphPublisher
					      opengraphSiteName
					      opengraphTitle
					      opengraphType
					      opengraphUrl
					      title
					      twitterDescription
					      twitterImage {
					        localFile {
					          childImageSharp {
					            fixed {
					              src
					            }
					          }
					        }
					      }
					      twitterTitle
					    }
					  }
					  wp {
					    generalSettings {
					      title
					      description
					    }
					  }
					  allWpProject(
					    filter: {visualIndexGallery: {visualIndexGallery: {elemMatch: {excludeFromMainVisualIndex: {ne: "exclude"}}}}}
					  ) {
					    nodes {
					      title
					      id
					      uri
					      slug
					      categories {
					        nodes {
					          name
					        }
					      }
					      featuredImage {
					        node {
					          localFile {
					            childImageSharp {
					              fluid {
					                ...GatsbyImageSharpFluid_noBase64
					              }
					            }
					            extension
					            publicURL
					          }
					        }
					      }
					      visualIndexImage {
					        visualIndexImage {
					          localFile {
					            publicURL
					            extension
					            childImageSharp {
					              fluid {
					                ...GatsbyImageSharpFluid_noBase64
					              }
					            }
					          }
					        }
					      }
					      hoverStateText {
					        hoverStateText
					      }
					      visualIndexGallery {
					        visualIndexGallery {
					        	caption
					          image {
					            localFile {
						            publicURL
					            	extension
					              childImageSharp {
					                fluid(maxWidth:1920) {
					                	...GatsbyImageSharpFluid_noBase64
					              	}
					              }
					            }
					          }
					        }
					      }
					    }
					  }
					}
					`}
					render={ ( projects ) => (
						<>
						<Seo 
				    	siteTitle={projects.wp.generalSettings.title}
							siteDescription={projects.wp.generalSettings.description}
							title={projects.wpPage.seo.title}
							metaDesc={projects.wpPage.seo.metaDesc}
							focusKeywords={projects.wpPage.seo.focuskw}
							opengraphTitle={projects.wpPage.seo.opengraphTitle}
							opengraphDescription={projects.wpPage.seo.opengraphDescription}
							opengraphImage={projects.wpPage.seo.opengraphImage}
							opengraphImage={projects.wpPage.seo.opengraphImage}
							twitterDescription={projects.wpPage.seo.twitterDescription}
							twitterImage={projects.wpPage.seo.twitterImage}
							twitterTitle={projects.wpPage.seo.twitterTitle}
				    />

						<Layout>
							<Header 
								title={ projects.wpPage.title } 
								url={ projects.wpPage.uri } 
								id={ projects.wpPage.id }
							/>

							{projects.allWpProject && (

								<div 
									className={ "visual-index grid grid:" + this.state.columns }
								>	
									<Projects 
										projects={ projects.allWpProject } 
										openModal={ this.modalOpen }
										setCategory={ this.setCategory }
										setLoadingOrder={ this.setLoadingOrder }
										loadingOrder={ this.state.loadingOrder }
										setSlug={ this.setSlug }
							      setSlides={ this.setSlides }
										setSlide={ this.setSlide }
										setTitle={ this.setTitle }
									/>

								</div>

							)}

							<Controls 
								zoomIn={ this.increaseZoom } 
								zoomOut={ this.decreaseZoom } 
								contrast={ this.contrastMode }
								shuffle={ this.shuffle }
							/>
							<div>
							</div>
							<Footer />
						</Layout>

						{ this.state.modal && (
							<AnimatePresence>
								<motion.article 
									variants={ fadeModal }
									initial="hidden"
									animate="show"
									exit="hidden"
									className="modal modal:light visual-index-modal"
								>

							        <Gallery 
							        	galleryToggled={ this.state.modal }
							        	gallerySlides={ projects.allWpProject }
							        	galleryLoadingOrder={ this.state.loadingOrder }
							        	setSlide={ this.state.slideIndex }
							        	setSlides={ this.state.setSlides }
							        	setTitle={ this.setTitle }
							        	setCategory={ this.setCategory }
							        	setSlug={ this.setSlug }
							        	galleryCategory={ this.state.category }
							        	gallerySlug={ this.state.slug }
							        	galleryTitle={ this.state.title }
							        	modalClose={ this.modalClose }
							        />
						        	
						        	<button 
						        		className="close-modal" 
						        		onClick={ this.modalClose }></button>

											<div className="cursor">
												<svg width="51" height="9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.242 0 0 4.243l4.242 4.242V0Z" fill="#fff"/><path d="M2.242 4.243h48" stroke="#fff"/></svg>
											</div>

								</motion.article>
							</AnimatePresence>
						)}

						</>
					)}
				/>
			</>
		);
	}
}

export default VisualIndex;