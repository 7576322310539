import React from 'react';
import Img from 'gatsby-image';
import '../../styles/components/project.scss';

class Thumbnail extends React.Component {

	constructor( props ) {
		super( props );
	}
	render() {
		// console.log(this.props.project.title)
		return (
			<>
				{ this.props.project.visualIndexGallery.visualIndexGallery && this.props.project.visualIndexGallery.visualIndexGallery.map((image, i) => (
				<a 
					className={ 'project' }
					data-index={ this.props.index }
					data-title={ this.props.project.title }
					data-slide-number={ i }
					key={ i }
					onClick={
						(e) => {
							e.preventDefault();
							this.props.openModal(e);
							this.props.setCategory( this.props.project.categories.nodes[0].name ); 
							this.props.setTitle( this.props.project.title );
							this.props.setSlug( this.props.project.slug );
							this.props.setSlide( e.currentTarget.dataset.slideNumber );
							this.props.setSlides( this.props.allProjects.nodes[this.props.index].visualIndexGallery )
						}
					}
				>
					<div>
						<figure>
							{ image.image.localFile && 
								image.image.localFile.extension !== 'gif' && (
								<Img 
									fluid={ image.image.localFile.childImageSharp.fluid }
									imgStyle={{
										objectFit: "contain"
									}}
								/>
							)}
							{ image.image.localFile && 
								image.image.localFile.extension === 'gif' && (
									<img 
										alt={ this.props.project.title } 
										style={{ objectFit: "contain" }} 
										src={ image.image.localFile.publicURL } 
								/>
							)}
						</figure>
					</div>
					
					{this.props.project.categories && (
						this.props.project.categories.nodes.map((category, i) => (
						<p 
							key={i} 
							className="text:small">{category.name}
						</p>
					)))}

					{this.props.project.hoverStateText && (
						<p className={ 'optional-caption text:small' }>{ this.props.project.hoverStateText.hoverStateText }</p>
					)}

				</a>
				))}
			</>
		);
	}

}

export default Thumbnail;